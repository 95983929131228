import { AccountManagementApiFactory } from '@/apis/general';
import { CreditSummary } from '@/apis/general/interfaces';
import { useGetPageData } from '@/hooks';
import { MinusCircleTwoTone, PlusCircleTwoTone, RightCircleTwoTone } from '@ant-design/icons';
import React from 'react';

const CreditSummaryComponent = () => {
  const api = AccountManagementApiFactory();

  const { execute, loading, data, error, setData } = useGetPageData<CreditSummary>(api.v1ManagementCreditsummaryGet, {}, true);

  if (!data) {
    return null;
  }

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ marginRight: 10 }}>
        <PlusCircleTwoTone twoToneColor="#52c41a" />
        <b style={{ marginLeft: 10 }}>Tanımlanan Kontör : </b>
        <span>{data?.totalDefinedCount ?? 0}</span>
      </div>
      <div style={{ marginRight: 10 }}>
        <MinusCircleTwoTone twoToneColor="#eb2f96" />
        <b style={{ marginLeft: 10 }}>Kullanılan Kontör : </b>
        <span>{data?.totalUsedCount ?? 0}</span>
      </div>

      <div style={{ marginRight: 10 }}>
        <MinusCircleTwoTone twoToneColor="#eb2f96" />
        <b style={{ marginLeft: 10 }}>Süresi Biten : </b>
        <span>{data?.totalExpiredUnUsedCount ?? 0}</span>
      </div>

      <div>
        <RightCircleTwoTone />
        <b style={{ marginLeft: 10 }}>Kalan Kontör : </b>
        <span>{(data?.totalDefinedCount ?? 0) - (data?.totalUsedCount ?? 0) - (data?.totalExpiredUnUsedCount ?? 0) }</span>
      </div>
    </div>
  );
};

export default CreditSummaryComponent;
