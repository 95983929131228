import { Option } from '@/interfaces';

export const EInvoiceProfileIdOptions: Option[] = [
  {
    text: 'Temel Fatura',
    value: 'TEMELFATURA'
  },
  {
    text: 'Ticari Fatura',
    value: 'TICARIFATURA'
  },
  {
    text: 'Hal Tipi Fatura',
    value: 'HKS'
  },
  {
    text: 'Kamu Faturası',
    value: 'KAMU'
  }
];

export const EArchiveProfileIdOptions: Option[] = [
  {
    text: 'E-Arşiv Fatura',
    value: 'EARSIVFATURA'
  }
];

export const EInvoiceInvoiceTypeOptions: Option[] = [
  {
    text: 'Satış',
    value: 'SATIS'
  },
  {
    text: 'İade',
    value: 'IADE'
  },
  {
    text: 'Tevkifat',
    value: 'TEVKIFAT'
  },
  {
    text: 'Tevkifatlı İade',
    value: 'TEVKIFATIADE'
  },
  {
    text: 'Vergi İstisna',
    value: 'ISTISNA'
  },
  {
    text: 'Özel Matrah',
    value: 'OZELMATRAH'
  },
  {
    text: 'İhraç Kayıtlı',
    value: 'IHRACKAYITLI'
  },
  {
    text: 'SGK',
    value: 'SGK'
  },
  {
    text: 'Komisyoncu',
    value: 'KOMISYONCU'
  }
];

export const EArchiveInvoiceTypeOptions: Option[] = [
  {
    text: 'Satış',
    value: 'SATIS'
  },
  {
    text: 'İade',
    value: 'IADE'
  },
  {
    text: 'Tevkifat',
    value: 'TEVKIFAT'
  },
  {
    text: 'Tevkifatlı İade',
    value: 'TEVKIFATIADE'
  },
  {
    text: 'Vergi İstisna',
    value: 'ISTISNA'
  },
  {
    text: 'Özel Matrah',
    value: 'OZELMATRAH'
  },
  {
    text: 'İhraç Kayıtlı',
    value: 'IHRACKAYITLI'
  },
  {
    text: 'H.K.S Satış',
    value: 'HKSSATIS'
  },
  {
    text: 'H.K.S Komisyoncu',
    value: 'HKSKOMISYONCU'
  }
];

export const SendTypeOptions: Option[] = [
  {
    text: 'Kağıt',
    value: 'KAGIT'
  },
  {
    text: 'Elektronik',
    value: 'ELEKTRONIK'
  }
];

export const SalesPlatformOptions: Option[] = [
  {
    text: 'Normal Satış',
    value: 'NORMAL'
  },
  {
    text: 'İnternet Satış',
    value: 'INTERNET'
  }
];

export const CurrencyCodeOptions: Option[] = [
  { text: 'BAE Dirhemi (Dirhams)', value: 'AED' },
  { text: 'Afgan', value: 'AFN' },
  { text: 'Lek', value: 'ALL' },
  { text: 'Dram', value: 'AMD' },
  { text: 'Antiller Guldeni', value: 'ANG' },
  { text: 'Kwanza', value: 'AOA' },
  { text: 'Arjantin Pesosu', value: 'ARS' },
  { text: 'Avustralya Doları', value: 'AUD' },
  { text: 'Aruba Florini', value: 'AWG' },
  { text: 'Azerbaycan Manatı', value: 'AZN' },
  { text: 'Konvertible Mark', value: 'BAM' },
  { text: 'Barbados Doları', value: 'BBD' },
  { text: 'Taka', value: 'BDT' },
  { text: 'Bulgaristan Levi', value: 'BGN' },
  { text: 'Bahreyn Dinarı', value: 'BHD' },
  { text: 'Burundi Frankı', value: 'BIF' },
  { text: 'Bermuda Doları', value: 'BMD' },
  { text: 'Brunei Doları', value: 'BND' },
  { text: 'Bolivya Bolivyanosu', value: 'BOB' },
  { text: 'Bolivya Mvdolu (Para kodu)', value: 'BOV' },
  { text: 'Brazilya Reali', value: 'BRL' },
  { text: 'Bahama Doları', value: 'BSD' },
  { text: 'Ngultrum', value: 'BTN' },
  { text: 'Botsvana Pulası', value: 'BWP' },
  { text: 'Belarus Rublesi', value: 'BYR' },
  { text: 'Belize Doları', value: 'BZD' },
  { text: 'Kanada Doları', value: 'CAD' },
  { text: 'Kongo Frankı', value: 'CDF' },
  { text: 'İsviçre Frankı', value: 'CHF' },
  { text: 'WIR Franc&nbsp;(Tamamlayıcı para birimi)', value: 'CHW' },
  { text: 'Unidades de formento&nbsp;(Para kodu)', value: 'CLF' },
  { text: 'Şili Pesosu', value: 'CLP' },
  { text: 'Yuan Renminbisi', value: 'CNY' },
  { text: 'Kolombiya Pesosu', value: 'COP' },
  { text: 'Unidad de Valor Real', value: 'COU' },
  { text: 'Kosta Rika Colonu', value: 'CRC' },
  { text: 'Küba Pesosu', value: 'CUP' },
  { text: 'Cape Verde Escudosu', value: 'CVE' },
  { text: 'Kıbrıs Lirası', value: 'CYP' },
  { text: 'Çek Korunası', value: 'CZK' },
  { text: 'Cibuti Frankı', value: 'DJF' },
  { text: 'Danimarka Kronu', value: 'DKK' },
  { text: 'Dominik Pesosu', value: 'DOP' },
  { text: 'Cezayir Dinarı', value: 'DZD' },
  { text: 'Estonya Kronu', value: 'EEK' },
  { text: 'Mısır Lirası/ Gineih', value: 'EGP' },
  { text: 'Nakfa', value: 'ERN' },
  { text: 'Etiyopya Birri', value: 'ETB' },
  { text: 'Euro', value: 'EUR' },
  { text: 'Fiji Doları', value: 'FJD' },
  { text: 'Falkland Adaları Lirası', value: 'FKP' },
  { text: 'İngiliz Sterlini/İngiliz Lirası', value: 'GBP' },
  { text: 'Gürcistan Lirası/ Lari', value: 'GEL' },
  { text: 'Cedi', value: 'GHC' },
  { text: 'Cebelitarık Lirası', value: 'GIP' },
  { text: 'Dalasi', value: 'GMD' },
  { text: 'Gine Frangı', value: 'GNF' },
  { text: 'Guatemala Kuetzalı', value: 'GTQ' },
  { text: 'Guyana Doları', value: 'GYD' },
  { text: 'Hong Kong Doları', value: 'HKD' },
  { text: 'Honduras Lempirası', value: 'HNL' },
  { text: 'Hırvatistan Kunası', value: 'HRK' },
  { text: 'Haiti Gourde', value: 'HTG' },
  { text: 'Macar Forinti', value: 'HUF' },
  { text: 'Rupiah', value: 'IDR' },
  { text: 'İsrail Yeni Şekeli', value: 'ILS' },
  { text: 'Hindistan Rupisi', value: 'INR' },
  { text: 'Irak Dinarı', value: 'IQD' },
  { text: 'İran Riyali', value: 'IRR' },
  { text: 'İzlanda Kronası', value: 'ISK' },
  { text: 'Jamaika Doları', value: 'JMD' },
  { text: 'Ürdün Dinarı', value: 'JOD' },
  { text: 'Japon Yeni', value: 'JPY' },
  { text: 'Kenya Şilini', value: 'KES' },
  { text: 'Kırgızistan Somu', value: 'KGS' },
  { text: 'Riel', value: 'KHR' },
  { text: 'Komoro Frankı', value: 'KMF' },
  { text: 'Kuzey Kore Wonu', value: 'KPW' },
  { text: 'Güney Kore Wonu', value: 'KRW' },
  { text: 'Kuveyt Dinarı', value: 'KWD' },
  { text: 'Cayman Adaları Doları', value: 'KYD' },
  { text: 'Kazak Tengesi', value: 'KZT' },
  { text: 'Kip', value: 'LAK' },
  { text: 'Lübnan Lirası', value: 'LBP' },
  { text: 'Sri Lanka Rupisi', value: 'LKR' },
  { text: 'Liberya Doları', value: 'LRD' },
  { text: 'Loti', value: 'LSL' },
  { text: 'Litvanya Litası', value: 'LTL' },
  { text: 'Letonya Latsı', value: 'LVL' },
  { text: 'Libya Dinarı', value: 'LYD' },
  { text: 'Fas Dirhemı', value: 'MAD' },
  { text: 'Moldovya Leusu', value: 'MDL' },
  { text: 'Madagaskar Ariarisi', value: 'MGA' },
  { text: 'Makedon Dinarı', value: 'MKD' },
  { text: 'Kyat', value: 'MMK' },
  { text: 'Tugrik', value: 'MNT' },
  { text: 'Macau Patakası', value: 'MOP' },
  { text: 'Ouguiya', value: 'MRO' },
  { text: 'Malta Lirası', value: 'MTL' },
  { text: 'Mauritius Rupisi', value: 'MUR' },
  { text: 'Maldiv Rupisi&nbsp;Rufiyaa', value: 'MVR' },
  { text: 'Kwacha', value: 'MWK' },
  { text: 'Meksika Pesosu', value: 'MXN' },
  { text: 'Mexican Unidad de Inversion&nbsp;(UDI) (Para kodu)', value: 'MXV' },
  { text: 'Malezya Ringgiti', value: 'MYR' },
  { text: 'Mozambik Metikali', value: 'MZN' },
  { text: 'Namibya Doları', value: 'NAD' },
  { text: 'Naira', value: 'NGN' },
  { text: 'Kordoba', value: 'NIO' },
  { text: 'Norveç Kronu', value: 'NOK' },
  { text: 'Nepal Rupisi', value: 'NPR' },
  { text: 'Yeni Zelanda Doları', value: 'NZD' },
  { text: 'Umman Riyali', value: 'OMR' },
  { text: 'Balboa', value: 'PAB' },
  { text: 'Nuevo Sol', value: 'PEN' },
  { text: 'Kina', value: 'PGK' },
  { text: 'Filipinler Pesosu', value: 'PHP' },
  { text: 'Pakistan Rupisi', value: 'PKR' },
  { text: 'Leh Zlotisi', value: 'PLN' },
  { text: 'Guarani', value: 'PYG' },
  { text: 'Katar Riyali', value: 'QAR' },
  { text: 'Eski Romanya Leyi', value: 'ROL' },
  { text: 'Yeni Ley', value: 'RON' },
  { text: 'Sırbistan Dinarı', value: 'RSD' },
  { text: 'Rus Rublesi', value: 'RUB' },
  { text: 'Rıanda Frankı', value: 'RWF' },
  { text: 'Suudi Riyali', value: 'SAR' },
  { text: 'Solomon Adaları Doları', value: 'SBD' },
  { text: 'Seyşeller Rupisi', value: 'SCR' },
  { text: "Sudan Dinar'ı", value: 'SDD' },
  { text: 'İsveç Kronu', value: 'SEK' },
  { text: 'Singapur Doları', value: 'SGD' },
  { text: 'Saint Helena Lira', value: 'SHP' },
  { text: 'Tolar', value: 'SIT' },
  { text: 'Slovak Korunası', value: 'SKK' },
  { text: 'Leone', value: 'SLL' },
  { text: 'Somali Şilini', value: 'SOS' },
  { text: 'Surinam Doları', value: 'SRD' },
  { text: 'Dobra', value: 'STD' },
  { text: 'Suriye Lirası', value: 'SYP' },
  { text: 'Lilangeni', value: 'SZL' },
  { text: 'Baht', value: 'THB' },
  { text: 'Somoni', value: 'TJS' },
  { text: 'Turkmenistan Manatı', value: 'TMM' },
  { text: 'Tunus Dinarı', value: 'TND' },
  { text: "Pa'anga", value: 'TOP' },
  { text: 'Türk Lirası', value: 'TRY' },
  { text: 'Trinidad ve Tobago Doları', value: 'TTD' },
  { text: 'Yeni Tayvan Doları', value: 'TWD' },
  { text: 'Tanzanya Şilini', value: 'TZS' },
  { text: 'Ukrayna Grivnası', value: 'UAH' },
  { text: 'Uganda Şilini', value: 'UGX' },
  { text: 'Amerikan Doları', value: 'USD' },
  { text: 'Uruguay Pesosu', value: 'UYU' },
  { text: 'Özbekistan Somu', value: 'UZS' },
  { text: 'Venezuela Bolivarı', value: 'VEB' },
  { text: 'Dong', value: 'VND' },
  { text: 'Vatu', value: 'VUV' },
  { text: 'Samoa Talası', value: 'WST' },
  { text: 'CFA Franc BEAC', value: 'XAF' },
  { text: 'Altın&nbsp;(1&nbsp;Troy ons) (=31.1034768 gram)', value: 'XAU' },
  { text: 'Doğu Karayip doları', value: 'XCD' },
  { text: 'Yemen Riyali', value: 'YER' },
  { text: 'Güney Afrika Randı', value: 'ZAR' },
  { text: 'Kwacha', value: 'ZMK' },
  { text: 'Zimbabve Doları', value: 'ZWD' }
];

export const UnitCodeOptions: Option[] = [
  { text: 'Gün', value: 'DAY' },
  { text: 'Ay', value: 'MON' },
  { text: 'Yıl', value: 'ANN' },
  { text: 'Saat', value: 'HUR' },
  { text: 'Dakika', value: 'D61' },
  { text: 'Saniye', value: 'D62' },
  { text: 'Adet', value: 'C62' },
  { text: 'Paket', value: 'PA' },
  { text: 'Kutu', value: 'BX' },
  { text: 'mg', value: 'MGM' },
  { text: 'g', value: 'GRM' },
  { text: 'kg', value: 'KGM' },
  { text: 'lt', value: 'LTR' },
  { text: 'ton', value: '26' },
  { text: 'Net Ton', value: 'NT' },
  { text: 'Gross ton', value: 'GT' },
  { text: 'mm', value: 'MMT' },
  { text: 'cm', value: 'CMT' },
  { text: 'm', value: 'MTR' },
  { text: 'km', value: 'KTM' },
  { text: 'ml', value: 'MLT' },
  { text: 'mm3', value: 'MMQ' },
  { text: 'cm2', value: 'CMK' },
  { text: 'cm3', value: 'CMQ' },
  { text: 'm2', value: 'MTK' },
  { text: 'm3', value: 'MTQ' },
  { text: 'kJ', value: 'KJO' },
  { text: 'cl', value: 'CLT' },
  { text: 'KWH', value: 'KWH' },
  { text: 'Ton baş.taşıma kap.', value: 'CCT' },
  { text: 'Brüt kalori', value: 'D30' },
  { text: '1000 lt', value: 'D40' },
  { text: 'Saf alkol lt', value: 'LPA' },
  { text: 'kg.m2', value: 'B32' },
  { text: 'Hücre adet', value: 'NCL' },
  { text: 'Çift', value: 'PR' },
  { text: '1000 m3', value: 'R9' },
  { text: 'Set', value: 'SET' },
  { text: '1000 adet', value: 'T3' },
  { text: 'Rulo', value: 'RO' },
  { text: 'Yard', value: 'YRD' },
  { text: 'Koli', value: 'PK' },
  { text: 'Palet', value: 'PF' },
  { text: 'Sandık', value: 'CR' },
  { text: 'Çuval', value: 'SA' },
  { text: 'Poşet', value: 'BG' },
  { text: 'Kamyon', value: 'TC' },
  { text: 'Konteynır', value: 'CH' },
  { text: 'Ton', value: 'TNE' },
  { text: 'dm3', value: 'DM3' },
  { text: 'mm2', value: 'MMK' },
  { text: 'dm2', value: 'DMK' },
  { text: 'Dönüm', value: 'ACR' },
  { text: 'Hafta', value: 'WEE' },
  { text: 'Karat', value: 'CT' }
];

export const VATType: Option[] = [
  { value: 0, text: 'Vergisiz' },
  { value: 1, text: '% 1' },
  { value: 8, text: '% 8' },
  { value: 18, text: '% 18' }
];

export const OzelMatrahOptions: Option[] = [
  { text: 'Özel Matrah Değil', value: '0' },
  { text: 'Milli Piyango, Spor Toto vb. Oyunlar', value: '801' },
  { text: 'At yarışları ve diğer müşterek bahis ve talih oyunları', value: '802' },
  {
    text: 'Profesyonel Sanatçıların Yer Aldığı Gösteriler, Konserler, Profesyonel Sporcuların Katıldığı Sportif Faaliyetler, Maçlar, Yarışlar ve Yarışmalar',
    value: '803'
  },
  { text: 'Gümrük Depolarında ve Müzayede Mahallerinde Yapılan Satışlar', value: '804' },
  { text: 'Altından Mamül veya Altın İçeren Ziynet Eşyaları İle Sikke Altınların Teslimi', value: '805' },
  { text: 'Tütün Mamülleri', value: '806' },
  { text: 'Muzır Neşriyat Kapsamındaki  Gazete, Dergi vb. Periyodik Yayınlar', value: '807' },
  { text: 'Gümüşten Mamul veya Gümüş İçeren Ziynet Eşyaları ile Sikke Gümüşlerin Teslimi', value: '808' },
  { text: 'Belediyeler taraf. yap. şehiriçi yolcu taşımacılığında kullanılan biletlerin ve kartların bayiler tarafından satışı', value: '809' },
  { text: 'Ön Ödemeli Elektronik Haberleşme Hizmetleri', value: '810' },
  { text: 'TŞOF Tarafından Araç Plakaları ile Sürücü Kurslarında Kullanılan Bir Kısım Evrakın Teslimi', value: '811' },
  { text: 'KDV Uygulanmadan Alınan İkinci El Motorlu Kara Taşıtı veya Taşınmaz Teslimi', value: '812' }
];

export const TaxFreeOptions: Option[] = [
  { text: 'Seçiniz...', value: '0' },
  { text: "501 - Türkiye'de İkamet Etmeyenlere KDV Hesaplanarak Yapılan Satışlar(Yolcu Beraberi Eşya)", value: '501' }
];

export const IstisnaOptions: Option[] = [
  { text: 'Seçiniz...', value: '0' },
  { text: '201 - 17/1 Kültür ve eğitim amacı taşıyan işlemler', value: '201' },
  { text: '202 - 17/2-a Sağlık, çevre ve sosyal yardım amaçlı işlemler', value: '202' },
  { text: '204 - 17/2-c Yabancı diplomatik organ ve hayır kurumlarının bağışlarıyla ilgili mal ve hizmet alışları', value: '204' },
  { text: '205 - 17/2-d Taşınmaz kültür varlıklarına ilişkin teslimler ve mimarlık hizmetleri', value: '205' },
  { text: '206 - 17/2-e Mesleki kuruluşların işlemleri', value: '206' },
  { text: '207 - 17/3 Askeri fabrika', value: '207' },
  { text: '208 - 17/4-c Birleşme, devir, dönüşüm ve bölünme işlemleri', value: '208' },
  { text: '209 - 17/4-e Banka ve sigorta muameleleri vergisi kapsamına giren işlemler', value: '209' },
  { text: '211 - 17/4-h Zirai amaçlı veya köy tüzel kişiliklerince yapılan içme suyu teslimleri', value: '211' },
  { text: '212 - 17/4-ı Serbest bölgelerde verilen hizmetler', value: '212' },
  { text: '213 - 17/4-j Boru hattı ile yapılan petrol ve gaz taşımacılığı', value: '213' },
  { text: '214 - 17/4-k Sanayi bölgelerindeki arsa ve işyeri teslimleri ile konut yapı kooperatiflerinin üyelerine konut teslimleri', value: '214' },
  { text: '215 - 17/4-l Varlık yönetim şirketlerinin işlemleri', value: '215' },
  { text: '216 - 17/4-m Tasarruf mevduatı sigorta fonunun işlemleri', value: '216' },
  { text: '217 - 17/4-n Basın - Yayın ve Enformasyon Genel Müdürlüğüne verilen haber hizmetleri', value: '217' },
  {
    text: '218 - 17/4-o Gümrük antrepoları, geçici depolama yerleri, gümrüklü sahalar ve vergisiz satış yapılan mağazalarla ilgili hizmetler',
    value: '218'
  },
  { text: '219 - 17/4-p Hazine ve Arsa Ofisi Genel Müdürlüğünün işlemleri', value: '219' },
  {
    text: '17/4-r İki Tam Yıl Süreyle Sahip Olunan Taşınmaz ve İştirak Hisseleri ile 15/7/2023 tarihinden önce kurumların aktifinde kayıtlı Taşınmaz satışı',
    value: '220'
  },
  { text: '221 - Geçici 15 Konut yapı kooperatifleri, belediyeler ve sosyal güvenlik kuruluşlarına verilen inşaat taahhüt hizmeti', value: '221' },
  { text: '223 - Geçici 20/1 Teknoloji geliştirme bölgelerinde yapılan işlemler', value: '223' },
  { text: '225 - Geçici 23 Milli Eğitim Bakanlığına yapılan bilgisayar bağışları ile ilgili teslimler', value: '225' },
  { text: '226 - 17/2-b Özel Okulları, Üniversite ve Yüksekokullar Tarafından Verilen Bedelsiz Eğitim Ve Öğretim Hizmetleri', value: '226' },
  { text: '227 - 17/2-b Kanunların Gösterdiği Gerek Üzerine Bedelsiz Olarak Yapılan Teslim ve Hizmetler', value: '227' },
  { text: '228 - 17/2-b Kanunun (17/1) Maddesinde Sayılan Kurum ve Kuruluşlara Bedelsiz Olarak Yapılan Teslimler', value: '228' },
  {
    text: '229 - 17/2-b Gıda Bankacılığı Faaliyetinde Bulunan Dernek ve Vakıflara Bağışlanan Gıda, Temizlik, Giyecek ve Yakacak Maddeleri',
    value: '229'
  },
  { text: '230 - 17/4-g Külçe Altın, Külçe Gümüş Ve Kiymetli Taşlarin Teslimi', value: '230' },
  { text: '231 - 17/4-g Metal Plastik, Lastik, Kauçuk, Kağit, Cam Hurda Ve Atıkların Teslimi', value: '231' },
  { text: '232 - 17/4-g Döviz, Para, Damga Pulu, Değerli Kağıtlar, Hisse Senedi ve Tahvil Teslimleri', value: '232' },
  { text: '234 - 17/4-ş Konut Finansmanı Amacıyla Teminat Gösterilen ve İpotek Konulan Konutların Teslimi', value: '234' },
  {
    text: '235 - 16/1-c Transit ve Gümrük Antrepo Rejimleri İle Geçici Depolama ve Serbest Bölge Hükümlerinin Uygulandığiı Malların Teslimi',
    value: '235'
  },
  { text: '236 - 19/2 Usulüne Göre Yürürlüğe Girmiş Uluslararası Anlaşmalar Kapsamındaki İstisnalar (İade Hakkı Tanınmayan)', value: '236' },
  {
    text: '237 - 17/4-t 5300 Sayılı Kanuna Göre Düzenlenen Ürün Senetlerinin İhtisas/Ticaret Borsaları Aracılığıyla İlk Teslimlerinden Sonraki Teslim',
    value: '237'
  },
  {
    text:
      '238 - 17/4-u Varlıkların  Varlık  Kiralama  Şirketlerine  Devri  İle  Bu  Varlıkların  Varlık Kiralama Şirketlerince Kiralanması ve Devralınan Kuruma Devri',
    value: '238'
  },
  {
    text: '239 - 17/4-y Taşınmazların  Finansal  Kiralama  Şirketlerine  Devri, Finansal  Kiralama Şirketi Tarafından Devredene Kiralanması ve Devri',
    value: '239'
  },
  { text: '240 - 17/4-z Patentli Veya Faydalı Model Belgeli Buluşa İlişkin Gayri Maddi Hakların Kiralanması, Devri ve Satışı', value: '240' },

  { text: '241 - TürkAkım Gaz Boru Hattı Projesine İlişkin Anlaşmanın (9/b) Maddesinde Yer Alan Hizmetler', value: '241' },
  {
    text:
      '242 - KDV 17/4-ö md. Gümrük Antrepoları, Geçici Depolama Yerleri ile Gümrüklü Sahalarda, İthalat ve İhracat İşlemlerine konu mallar ile transit rejim kapsamında işlem gören mallar için verilen ardiye, depolama ve terminal hizmetleri',
    value: '242'
  },

  { text: '250 - Kısmi İstisna Diğer', value: '250' },
  { text: '301 - 11/1-a Mal ihracatı', value: '301' },
  { text: '302 - 11/1-a Hizmet ihracatı', value: '302' },
  { text: '303 - 11/1-a Roaming hizmetleri', value: '303' },
  { text: '304 - 13/a Deniz, hava, demiryolu taşıma araç. tes. ile inşa, tadil, bakım, onarım', value: '304' },
  { text: '305 - 13/b Deniz, hava taşıma araç. için liman, hava mey. yapılan hiz.', value: '305' },
  { text: '306 - 13/c Petrol aramaları ve petrol boru haztlarının inşa ve modern. ilişkin teslim hiz.', value: '306' },
  {
    text:
      '307 - 13/c Maden Arama, Altın, Gümüş, ve Platin Madenleri İçin İşletme, Zenginleştirme Ve Rafinaj Faaliyetlerine İlişkin Teslim Ve Hizmetler KDVGUT-(II/8-4)',
    value: '307'
  },
  { text: '308 - 13/d Teşvikli yatırım mal tes.', value: '308' },
  { text: '309 - 13/e Limanlara bağlantı sağ. demiryolu hatları ile liman ve hava mey. inşa, yenilenme ve genişletilmesi', value: '309' },
  { text: '310 - 13/f Ulusal güvenlik amaçlı teslim ve hizmetler', value: '310' },
  { text: '311 - 14/1 Uluslararası taşımacılık', value: '311' },
  { text: '312 - 15/a Diplomatik organ ve misyonlara yapılan teslim ve hizmetler', value: '312' },
  { text: '313 - 15/b Uluslarası kuruluşlara yapılan teslim ve hizmetler', value: '313' },
  { text: '314 - 19/2 Usulüne göre yürürlüğe girmiş uluslararası anlaşmalar kapsamındaki istisnalar(İade hakkı tanınan)', value: '314' },
  { text: '315 - 14/3 İhraç konusu eşya taşıyan kamyon, çekici ve yarı romorklara yapılan motorin teslimleri', value: '315' },
  { text: '316 - 11/1-a Serbest böl. müş. fason hiz.', value: '316' },
  { text: '317 - 17/4-s Özürlülerin eğitim, meslek ve günlük yaşam. ilişkin araç-gereç ve bilgisayar programları', value: '317' },
  {
    text: '318 - Geçici 29 Yap-İşlet-Devret modeli projeler, kiralama karş. yapt. sağlık tesis.projeler, kiralama karş. yapt. eğitim öğretim tesis. tes. hiz.',
    value: '318'
  },
  { text: '319 - 13/g Başbakanlık Merkez Teşkilatına yapılan araç teslimler', value: '319' },
  { text: '320 - Geçici 16 İSMEP kapsamında İst. İl Özel İd. bağlı faal. göst. "İst.Proje Koor.Brm."ne yapılacak tes. hiz.', value: '320' },
  {
    text: '321 - Geçici 26 BM, NATO(temsilcilikleri, bağlı program, fon, özel ihtisas), OECD resmi kullanımları için yapılacak mal tes. hiz. ifaları',
    value: '321'
  },
  { text: "322 - 11/1-a Türkiye'de İkamet Etmeyenlere Özel Fatura ile Yapılan Teslimler (Bavul Ticareti)", value: '322' },
  { text: '323 - 13/ğ 5300 Sayılı Kanuna Göre Düzenlenen Ürün Senetlerinin İhtisas/Ticaret Borsaları Aracılığıyla İlk Teslimi', value: '323' },
  { text: '324 - 13/h Türkiye Kızılay Derneğine Yapılan Teslim ve Hizmetler ile Türkiye Kızılay Derneğinin Teslim ve Hizmetleri', value: '324' },
  { text: '325 - 13/ı Yem Teslimleri', value: '325' },
  { text: '326 - 13/ı Gıda, Tarım ve Hayvancılık Bakanlığı Tarafından Tescil Edilmiş Gübrelerin Teslimi', value: '326' },
  {
    text: '327 - 13/ı Gıda, Tarım ve Hayvancılık Bakanlığı Tarafından Tescil Edilmiş Gübrelerin İçeriğinde Bulunan Hammaddelerin Gübre Üreticilerine Teslimi',
    value: '327'
  },
  { text: '328 - 13/i Konut veya İşyeri Teslimleri', value: '328' },
  { text: '328 - 13/i Konut veya İşyeri Teslimleri', value: '328' },
  {
    text:
      '329 - Eğitimde Fırsatları Artırma ve Teknolojiyi İyileştirme Hareketi (FATİH) projesi kapsamında Milli Eğitim Bakanlığına Yapılacak Mal Teslimi ve Hizmet İfası',
    value: '329'
  },

  { text: '330 - KDV 13/j md. Organize Sanayi Bölgeleri ile Küçük Sanayi Sitelerinin İnşasına İlişkin Teslim ve Hizmetler', value: '330' },
  {
    text: '331 - KDV 13/m md. Ar-Ge, Yenilik ve Tasarım Faaliyetlerinde Kullanılmak Üzere Yapılan Yeni Makina ve Teçhizat Teslimlerinde İstisna',
    value: '331'
  },
  { text: '332 - KDV Geçici 39. Md. İmalat Sanayiinde Kullanılmak Üzere Yapılan Yeni Makina ve Teçhizat Teslimlerinde İstisna', value: '332' },
  {
    text:
      '333 - KDV 13/k md. Kapsamında Genel ve Özel Bütçeli Kamu İdarelerine, İl Özel İdarelerine, Belediyelere ve Köylere bağışlanan Tesislerin İnşasına İlişkin İstisna',
    value: '333'
  },
  { text: '334 - KDV 13/l md. Kapsamında Yabancılara Verilen Sağlık Hizmetlerinde İstisna', value: '334' },
  { text: '335 - 13/n md. Basılı Kitap ve Süreli Yayınların Teslimleri', value: '335' },
  { text: '336 - Geçici 40 UEFA Müsabakaları Kapsamında Yapılacak Teslim ve Hizmetler', value: '336' },

  { text: '337 - Türk Akım Gaz Boru Hattı Projesine İlişkin Anlaşmanın (9/h) Maddesi Kapsamındaki Gaz Taşıma Hizmetleri', value: '337' },
  { text: '338 - İmalatçıların Mal İhracatları', value: '338' },
  { text: '339 - İmalat Sanayii ile Turizme Yönelik Yatırım Teşvik Belgesi Kapsamındaki İnşaat İşlerine İlişkin Teslim ve Hizmetler', value: '339' },
  { text: '340 - Elektrik Motorlu Taşıt Araçlarının Geliştirilmesine Yönelik Mühendislik Hizmetleri', value: '340' },
  { text: '342 - Genel Bütçeli Kamu İdarelerine Bağışlanacak Taşınmazların İnşasına İlişkin İstisna', value: '342' },
  { text: '343 - Genel Bütçeli Kamu İdarelerine Bağışlanacak Konutların Yabancı Devlet Kurum ve Kuruluşlarına Teslimine İlişkin İstisna', value: '343' },

  { text: '350 - İstisna Diğer', value: '350' }
];

export const IhracatIstisnaOptions: Option[] = [
  { text: 'Seçiniz...', value: '0' },
  { text: '235 - 16/1-c Transit ve Gümrük Antrepo Rejimleri İle Geçici Depolama ve Serbest Bölge Hükümlerinin Uygulandığiı Malların Teslimi', value: '235' },
  { text: '301 - 11/1-a Mal ihracatı', value: '301' },
  { text: '302 - 11/1-a Hizmet ihracatı', value: '302' },
  { text: '338 - İmalatçıların Mal İhracatları', value: '338' },
  {
    text: '218 - 17/4-o Gümrük antrepoları, geçici depolama yerleri, gümrüklü sahalar ve vergisiz satış yapılan mağazalarla ilgili hizmetler',
    value: '218'
  }
];

export const IstisnaDigerOptions: Option[] = [
  { text: 'Seçiniz...', value: '0' },
  { text: '351 - İstisna Olmayan Diğer', value: '351' }
];

export const DeliveryTermsOptions: Option[] = [
  { text: 'Seçiniz...', value: '0' },
  { text: 'CFR - Masraflar ve Navlun', value: 'CFR' },
  { text: 'CIF - Masraflar, Sigorta ve Navlun', value: 'CIF' },
  { text: 'CIP - Taşıma ve Sigorta Ücreti', value: 'CIP' },
  { text: 'CPT - Ödenen Taşıma Ücreti', value: 'CPT' },
  { text: 'DAF - Sınırdan Teslim', value: 'DAF' },
  { text: 'DDP - Ödenen Gümrük Ücreti', value: 'DDP' },
  { text: 'DDU - Ödenmeyen Gümrük Ücreti', value: 'DDU' },
  { text: 'DEQ - Rıhtımdan Teslim', value: 'DEQ' },
  { text: 'DES - Gemiden Teslim', value: 'DES' },
  { text: 'EXW - Fabrikadan Teslim', value: 'EXW' },
  { text: 'FAS - Gemide Ücretsiz', value: 'FAS' },
  { text: 'FCA - Ücretsiz Taşıma', value: 'FCA' },
  { text: 'FOB - Güvertede Teslim', value: 'FOB' },
  { text: 'DAP - Belirlenen Yerde Teslim', value: 'DAP' }
];

export const TransportModeCodeOptions: Option[] = [
  { text: 'Seçiniz...', value: '0' },
  { text: 'Deniz Taşımacılığı', value: '1' },
  { text: 'Demiryolu Taşımacılığı', value: '2' },
  { text: 'Karayolu Taşımacılığı', value: '3' },
  { text: 'Hava Taşımacılığı', value: '4' },
  { text: 'Posta', value: '5' },
  { text: 'Kombine Taşımacılık', value: '6' },
  { text: 'Sabit Nakliyat', value: '7' },
  { text: 'Ülke İçi Su Taşımacılığı', value: '8' },
  { text: 'Uygun Olmayan Taşıma Şekli', value: '9' }
];

export const ActualPackageTypeCode: Option[] = [
  { text: 'Seçiniz...', value: '0' },
  { text: 'AE - AEROSOL', value: 'AE' },
  { text: 'AM - KORUMALI AMPUL', value: 'AM' },
  { text: 'AP - KORUMASIZ AMPUL', value: 'AP' },
  { text: 'AT - PÜSKÜRGEÇ', value: 'AT' },
  { text: 'BA - VARIL', value: 'BA' },
  { text: 'BB - BOBIN', value: 'BB' },
  { text: 'BC - SISE KASASI', value: 'BC' },
  { text: 'BD - TAHTA', value: 'BD' },
  { text: 'BE - BOHÇA', value: 'BE' },
  { text: 'BF - KORUMASIZ BALON', value: 'BF' },
  { text: 'BG - ÇANTA', value: 'BG' },
  { text: 'BI - KAP', value: 'BI' },
  { text: 'BJ - KOVA', value: 'BJ' },
  { text: 'BK - SEPET', value: 'BK' },
  { text: 'BL - SIKISTIRILMIS BALYA', value: 'BL' },
  { text: 'BN - SIKISTIRILMAMIS BALYA', value: 'BN' },
  { text: 'BO - KORUMASIZ SILINDIRIK SISE', value: 'BO' },
  { text: 'BP - KORUMALI BALON', value: 'BP' },
  { text: 'BQ - KORUMALI SILINDIRIK SISE', value: 'BQ' },
  { text: 'BR - ÇUBUK', value: 'BR' },
  { text: 'BS - KORUMASIZ, SOGAN SEKLINDE SISE', value: 'BS' },
  { text: 'BT - CIVATA', value: 'BT' },
  { text: 'BU - BÜYÜK FIÇI', value: 'BU' },
  { text: 'BV - KORUMALI, SOGAN SEKLINDE SISE', value: 'BV' },
  { text: 'BX - KUTU', value: 'BX' },
  { text: 'CA - DIKDÖRTGEN MADENI KAP', value: 'CA' },
  { text: 'CB - BIRA KASASI', value: 'CB' },
  { text: 'CC - YAYIK', value: 'CC' },
  { text: 'CE - BALIK SEPETI', value: 'CE' },
  { text: 'CF - SANDIK', value: 'CF' },
  { text: 'CG - KAFES', value: 'CG' },
  { text: 'CH - SANDIK', value: 'CH' },
  { text: 'CJ - TABUT', value: 'CJ' },
  { text: 'CK - AHSAP VARIL', value: 'CK' },
  { text: 'CL - KANGAL', value: 'CL' },
  { text: 'CO - KORUMASIZ DAMACANA', value: 'CO' },
  { text: 'CP - KORUMALI DAMACANA', value: 'CP' },
  { text: 'CR - KASA', value: 'CR' },
  { text: 'CT - MUKAVVA KUTU', value: 'CT' },
  { text: 'CU - FINCAN', value: 'CU' },
  { text: 'CV - KAPALI', value: 'CV' },
  { text: 'CX - SILINDIRIK TENEKE KUTU ', value: 'CX' },
  { text: 'CY - SILINDIRIK', value: 'CY' },
  { text: 'CZ - ÇATIR BEZI', value: 'CZ' },
  { text: 'DJ - KORUMALI, HASIR BÜYÜK SISE', value: 'DJ' },
  { text: 'DP - KORUMASIZ, HASIRLI BÜYÜK SISE', value: 'DP' },
  { text: 'DR - DAVUL', value: 'DR' },
  { text: 'EN - ZARF', value: 'EN' },
  { text: 'FC - MEYVE KASASI', value: 'FC' },
  { text: 'FD - ÇERÇEVELI KASA', value: 'FD' },
  { text: 'FI - UFAK YAG FIÇISI', value: 'FI' },
  { text: 'FL - DAR BOYUNLU KÜÇÜK SISE', value: 'FL' },
  { text: 'FO - KÜÇÜK SANDIK', value: 'FO' },
  { text: 'FP - FOTOGRAF FILMLERI PAKETI', value: 'FP' },
  { text: 'FR - ÇERÇEVE', value: 'FR' },
  { text: 'GB - GAZ SISESI', value: 'GB' },
  { text: 'GE - GEMICI SANDIGI', value: 'GE' },
  { text: 'GI - KIRIS', value: 'GI' },
  { text: "HG - BÜYÜK FIÇI (250 LT'LIK)", value: 'HG' },
  { text: 'HR - KAPAKLI SEPET', value: 'HR' },
  { text: "JC - DIKDÖRTGEN BIDON (20LT'LIK)", value: 'JC' },
  { text: 'JG - SÜRAHI', value: 'JG' },
  { text: 'JR - KAVANOZ', value: 'JR' },
  { text: 'JT - JÜT (KENEVIR) TORBA', value: 'JT' },
  { text: "JY - SILINDIRIK BIDON (20 LT'LIK)", value: 'JY' },
  { text: 'KG - KÜÇÜK FIÇI', value: 'KG' },
  { text: 'CN - KONTEYNER', value: 'CN' },
  { text: 'LG - KÜTÜK', value: 'LG' },
  { text: 'MB - ÇOK GÖZLÜ ÇANTA', value: 'MB' },
  { text: 'MT - HASIR', value: 'MT' },
  { text: 'MX - KIBRIT KUTUSU', value: 'MX' },
  { text: 'NE - PAKETLENMEMIS VEYA AMBALAJLANMAMIS', value: 'NE' },
  { text: 'NS - YUVA', value: 'NS' },
  { text: 'NT - AG', value: 'NT' },
  { text: 'PA - PAKET', value: 'PA' },
  { text: 'PC - KOLI', value: 'PC' },
  { text: 'PG - TABLA', value: 'PG' },
  { text: 'PH - IBRIK', value: 'PH' },
  { text: 'PI - BORU', value: 'PI' },
  { text: 'PK - AMBALAJ', value: 'PK' },
  { text: 'PL - GERDEL', value: 'PL' },
  { text: 'PN - KALAS', value: 'PN' },
  { text: 'PO - KESE', value: 'PO' },
  { text: 'PT - ÇÖMLEK', value: 'PT' },
  { text: 'PU - TABLA PAKETI / TABLA', value: 'PU' },
  { text: 'RD - SOPA', value: 'RD' },
  { text: 'RG - HALKA (ÇEMBER)', value: 'RG' },
  { text: 'RL - MAKARA', value: 'RL' },
  { text: 'RO - RULO', value: 'RO' },
  { text: 'SA - ÇUVAL', value: 'SA' },
  { text: 'SC - DAR KASA', value: 'SC' },
  { text: 'SD - IG', value: 'SD' },
  { text: 'SE - GEMICI SANDIGI', value: 'SE' },
  { text: 'SH - KOKU YASTIGI', value: 'SH' },
  { text: 'SK - ISKELET KASA', value: 'SK' },
  { text: 'SM - SAC', value: 'SM' },
  { text: 'ST - TABAKA', value: 'ST' },
  { text: 'SU - BAVUL', value: 'SU' },
  { text: 'SW - ÇEKME - SARMA', value: 'SW' },
  { text: 'TB - GERDEL', value: 'TB' },
  { text: 'TC - ÇAY SANDIGI', value: 'TC' },
  { text: 'TD - AÇILIR KAPANIR TÜP / PORTATIF TÜP', value: 'TD' },
  { text: 'TK - DIKDÖRTGEN TANK', value: 'TK' },
  { text: 'TN - TENEKE KUTU', value: 'TN' },
  { text: 'TO - BÜYÜK FIÇI (250 GALONLUK)', value: 'TO' },
  { text: 'TR - BÜYÜK ESYA SANDIGI', value: 'TR' },
  { text: 'TS - KIRIS', value: 'TS' },
  { text: 'TU - KÜP', value: 'TU' },
  { text: 'TY - SILINDIRIK TANK', value: 'TY' },
  { text: 'VA - TEKNE', value: 'VA' },
  { text: 'VG - HACIM, GAZ (1031 m bar ve 15C)', value: 'VG' },
  { text: 'VI - CAM SISE', value: 'VI' },
  { text: 'VP - VAKUMLU PAKET', value: 'VP' },
  { text: 'VQ - HACIM, SIVI HALE GETIRILMIS GAZ (ANORMAL ISI/BASINÇ)', value: 'VQ' },
  { text: "VR - HACIM, KATI, GRANÜL PARÇACIKLARI ('TANELER')", value: 'VR' },
  { text: "VY - HACIM, KATI, INCE PARÇACIKLARI ('TOZ')", value: 'VY' },
  { text: 'WB - SEPET SISE', value: 'WB' }
];

export const CountryTypeOptions: Option[] = [
  { text: 'Seçiniz...', value: '0' },
  { value: 'AF', text: 'Afganistan' },
  { value: 'DE', text: 'Almanya' },
  { value: 'AD', text: 'Andorra' },
  { value: 'AO', text: 'Angola' },
  { value: 'AG', text: 'Antigua ve Barbuda' },
  { value: 'AR', text: 'Arjantin' },
  { value: 'AL', text: 'Arnavutluk' },
  { value: 'AW', text: 'Aruba' },
  { value: 'AU', text: 'Avustralya' },
  { value: 'AT', text: 'Avusturya' },
  { value: 'AZ', text: 'Azerbaycan' },
  { value: 'BS', text: 'Bahamalar' },
  { value: 'BH', text: 'Bahreyn' },
  { value: 'BD', text: 'Bangladeş' },
  { value: 'BB', text: 'Barbados' },
  { value: 'EH', text: 'Batı Sahra (MA)' },
  { value: 'BE', text: 'Belçika' },
  { value: 'BZ', text: 'Belize' },
  { value: 'BJ', text: 'Benin' },
  { value: 'BM', text: 'Bermuda' },
  { value: 'BY', text: 'Beyaz Rusya' },
  { value: 'BT', text: 'Bhutan' },
  { value: 'AE', text: 'Birleşik Arap Emirlikleri' },
  { value: 'US', text: 'Birleşik Devletler' },
  { value: 'GB', text: 'Birleşik Krallık' },
  { value: 'BO', text: 'Bolivya' },
  { value: 'BA', text: 'Bosna-Hersek' },
  { value: 'BW', text: 'Botsvana' },
  { value: 'BR', text: 'Brezilya' },
  { value: 'BN', text: 'Bruney' },
  { value: 'BG', text: 'Bulgaristan' },
  { value: 'BF', text: 'Burkina Faso' },
  { value: 'BI', text: 'Burundi' },
  { value: 'TD', text: 'Çad' },
  { value: 'KY', text: 'Cayman Adaları' },
  { value: 'GI', text: 'Cebelitarık (GB)' },
  { value: 'CZ', text: 'Çek Cumhuriyeti' },
  { value: 'DZ', text: 'Cezayir' },
  { value: 'DJ', text: 'Cibuti' },
  { value: 'CN', text: 'Çin' },
  { value: 'DK', text: 'Danimarka' },
  { value: 'CD', text: 'Demokratik Kongo Cumhuriyeti' },
  { value: 'TL', text: 'Doğu Timor' },
  { value: 'DO', text: 'Dominik Cumhuriyeti' },
  { value: 'DM', text: 'Dominika' },
  { value: 'EC', text: 'Ekvador' },
  { value: 'GQ', text: 'Ekvator Ginesi' },
  { value: 'SV', text: 'El Salvador' },
  { value: 'ID', text: 'Endonezya' },
  { value: 'ER', text: 'Eritre' },
  { value: 'AM', text: 'Ermenistan' },
  { value: 'MF', text: 'Ermiş Martin (FR)' },
  { value: 'EE', text: 'Estonya' },
  { value: 'ET', text: 'Etiyopya' },
  { value: 'FK', text: 'Falkland Adaları' },
  { value: 'FO', text: 'Faroe Adaları (DK)' },
  { value: 'MA', text: 'Fas' },
  { value: 'FJ', text: 'Fiji' },
  { value: 'CI', text: 'Fildişi Sahili' },
  { value: 'PH', text: 'Filipinler' },
  { value: 'FI', text: 'Finlandiya' },
  { value: 'FR', text: 'Fransa' },
  { value: 'GF', text: 'Fransız Guyanası (FR)' },
  { value: 'PF', text: 'Fransız Polinezyası (FR)' },
  { value: 'GA', text: 'Gabon' },
  { value: 'GM', text: 'Gambiya' },
  { value: 'GH', text: 'Gana' },
  { value: 'GN', text: 'Gine' },
  { value: 'GW', text: 'Gine Bissau' },
  { value: 'GD', text: 'Grenada' },
  { value: 'GL', text: 'Grönland (DK)' },
  { value: 'GP', text: 'Guadeloupe (FR)' },
  { value: 'GT', text: 'Guatemala' },
  { value: 'GG', text: 'Guernsey (GB)' },
  { value: 'ZA', text: 'Güney Afrika' },
  { value: 'KR', text: 'Güney Kore' },
  { value: 'GE', text: 'Gürcistan' },
  { value: 'GY', text: 'Guyana' },
  { value: 'HT', text: 'Haiti' },
  { value: 'IN', text: 'Hindistan' },
  { value: 'HR', text: 'Hırvatistan' },
  { value: 'NL', text: 'Hollanda' },
  { value: 'HN', text: 'Honduras' },
  { value: 'HK', text: 'Hong Kong (CN)' },
  { value: 'VG', text: 'İngiliz Virjin Adaları' },
  { value: 'IQ', text: 'Irak' },
  { value: 'IR', text: 'İran' },
  { value: 'IE', text: 'İrlanda' },
  { value: 'ES', text: 'İspanya' },
  { value: 'IL', text: 'İsrail' },
  { value: 'SE', text: 'İsveç' },
  { value: 'CH', text: 'İsviçre' },
  { value: 'IT', text: 'İtalya' },
  { value: 'IS', text: 'İzlanda' },
  { value: 'JM', text: 'Jamaika' },
  { value: 'JP', text: 'Japonya' },
  { value: 'JE', text: 'Jersey (GB)' },
  { value: 'KH', text: 'Kamboçya' },
  { value: 'CM', text: 'Kamerun' },
  { value: 'CA', text: 'Kanada' },
  { value: 'ME', text: 'Karadağ' },
  { value: 'QA', text: 'Katar' },
  { value: 'KZ', text: 'Kazakistan' },
  { value: 'KE', text: 'Kenya' },
  { value: 'CY', text: 'Kıbrıs' },
  { value: 'KG', text: 'Kırgızistan' },
  { value: 'KI', text: 'Kiribati' },
  { value: 'CO', text: 'Kolombiya' },
  { value: 'KM', text: 'Komorlar' },
  { value: 'CG', text: 'Kongo Cumhuriyeti' },
  { value: 'KV', text: 'Kosova (RS)' },
  { value: 'CR', text: 'Kosta Rika' },
  { value: 'CU', text: 'Küba' },
  { value: 'KW', text: 'Kuveyt' },
  { value: 'KP', text: 'Kuzey Kore' },
  { value: 'LA', text: 'Laos' },
  { value: 'LS', text: 'Lesoto' },
  { value: 'LV', text: 'Letonya' },
  { value: 'LR', text: 'Liberya' },
  { value: 'LY', text: 'Libya' },
  { value: 'LI', text: 'Lihtenştayn' },
  { value: 'LT', text: 'Litvanya' },
  { value: 'LB', text: 'Lübnan' },
  { value: 'LU', text: 'Lüksemburg' },
  { value: 'HU', text: 'Macaristan' },
  { value: 'MG', text: 'Madagaskar' },
  { value: 'MO', text: 'Makao (CN)' },
  { value: 'MK', text: 'Makedonya' },
  { value: 'MW', text: 'Malavi' },
  { value: 'MV', text: 'Maldivler' },
  { value: 'MY', text: 'Malezya' },
  { value: 'ML', text: 'Mali' },
  { value: 'MT', text: 'Malta' },
  { value: 'IM', text: 'Man Adası (GB)' },
  { value: 'MH', text: 'Marshall Adaları' },
  { value: 'MQ', text: 'Martinique (FR)' },
  { value: 'MU', text: 'Mauritius' },
  { value: 'YT', text: 'Mayotte (FR)' },
  { value: 'MX', text: 'Meksika' },
  { value: 'FM', text: 'Mikronezya' },
  { value: 'EG', text: 'Mısır' },
  { value: 'MN', text: 'Moğolistan' },
  { value: 'MD', text: 'Moldova' },
  { value: 'MC', text: 'Monako' },
  { value: 'MR', text: 'Moritanya' },
  { value: 'MZ', text: 'Mozambik' },
  { value: 'MM', text: 'Myanmar' },
  { value: 'NA', text: 'Namibya' },
  { value: 'NR', text: 'Nauru' },
  { value: 'NP', text: 'Nepal' },
  { value: 'NE', text: 'Nijer' },
  { value: 'NG', text: 'Nijerya' },
  { value: 'NI', text: 'Nikaragua' },
  { value: 'NO', text: 'Norveç' },
  { value: 'CF', text: 'Orta Afrika Cumhuriyeti' },
  { value: 'UZ', text: 'Özbekistan' },
  { value: 'PK', text: 'Pakistan' },
  { value: 'PW', text: 'Palau' },
  { value: 'PA', text: 'Panama' },
  { value: 'PG', text: 'Papua Yeni Gine' },
  { value: 'PY', text: 'Paraguay' },
  { value: 'PE', text: 'Peru' },
  { value: 'PL', text: 'Polonya' },
  { value: 'PT', text: 'Portekiz' },
  { value: 'PR', text: 'Porto Riko (US)' },
  { value: 'RE', text: 'Réunion (FR)' },
  { value: 'RO', text: 'Romanya' },
  { value: 'RW', text: 'Ruanda' },
  { value: 'RU', text: 'Rusya' },
  { value: 'BL', text: 'Saint Barthélemy (FR)' },
  { value: 'KN', text: 'Saint Kitts ve Nevis' },
  { value: 'LC', text: 'Saint Lucia' },
  { value: 'PM', text: 'Saint Pierre ve Miquelon (FR)' },
  { value: 'VC', text: 'Saint Vincent ve Grenadinler' },
  { value: 'WS', text: 'Samoa' },
  { value: 'SM', text: 'San Marino' },
  { value: 'ST', text: 'São Tomé ve Príncipe' },
  { value: 'SN', text: 'Senegal' },
  { value: 'SC', text: 'Seyşeller' },
  { value: 'SL', text: 'Sierra Leone' },
  { value: 'CL', text: 'Şili' },
  { value: 'SG', text: 'Singapur' },
  { value: 'RS', text: 'Sırbistan' },
  { value: 'SK', text: 'Slovakya Cumhuriyeti' },
  { value: 'SI', text: 'Slovenya' },
  { value: 'SB', text: 'Solomon Adaları' },
  { value: 'SO', text: 'Somali' },
  { value: 'SS', text: 'South Sudan' },
  { value: 'SJ', text: 'Spitsbergen (NO)' },
  { value: 'LK', text: 'Sri Lanka' },
  { value: 'SD', text: 'Sudan' },
  { value: 'SR', text: 'Surinam' },
  { value: 'SY', text: 'Suriye' },
  { value: 'SA', text: 'Suudi Arabistan' },
  { value: 'SZ', text: 'Svaziland' },
  { value: 'TJ', text: 'Tacikistan' },
  { value: 'TZ', text: 'Tanzanya' },
  { value: 'TH', text: 'Tayland' },
  { value: 'TW', text: 'Tayvan' },
  { value: 'TG', text: 'Togo' },
  { value: 'TO', text: 'Tonga' },
  { value: 'TT', text: 'Trinidad ve Tobago' },
  { value: 'TN', text: 'Tunus' },
  { value: 'TR', text: 'Türkiye' },
  { value: 'TM', text: 'Türkmenistan' },
  { value: 'TC', text: 'Turks ve Caicos' },
  { value: 'TV', text: 'Tuvalu' },
  { value: 'UG', text: 'Uganda' },
  { value: 'UA', text: 'Ukrayna' },
  { value: 'OM', text: 'Umman' },
  { value: 'JO', text: 'Ürdün' },
  { value: 'UY', text: 'Uruguay' },
  { value: 'VU', text: 'Vanuatu' },
  { value: 'VA', text: 'Vatikan' },
  { value: 'VE', text: 'Venezuela' },
  { value: 'VN', text: 'Vietnam' },
  { value: 'WF', text: 'Wallis ve Futuna (FR)' },
  { value: 'YE', text: 'Yemen' },
  { value: 'NC', text: 'Yeni Kaledonya (FR)' },
  { value: 'NZ', text: 'Yeni Zelanda' },
  { value: 'CV', text: 'Yeşil Burun Adaları' },
  { value: 'GR', text: 'Yunanistan' },
  { value: 'ZM', text: 'Zambiya' },
  { value: 'ZW', text: 'Zimbabve' }
];
