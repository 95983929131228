import { Option, ReasonOption } from '@/interfaces';

export const Months: Option[] = [
  {
    value: 'January',
    text: 'OCAK'
  },
  {
    value: 'February',
    text: 'SUBAT'
  },
  {
    value: 'March',
    text: 'MART'
  },
  {
    value: 'April',
    text: 'NISAN'
  },
  {
    value: 'May',
    text: 'MAYIS'
  },
  {
    value: 'June',
    text: 'HAZIRAN'
  },
  {
    value: 'July',
    text: 'TEMMUZ'
  },
  {
    value: 'August',
    text: 'AGUSTOS'
  },
  {
    value: 'September',
    text: 'EYLUL'
  },
  {
    value: 'October',
    text: 'EKIM'
  },
  {
    value: 'November',
    text: 'KASIM'
  },
  {
    value: 'December',
    text: 'ARALIK'
  }
];

export const DespatchProfileIdOptions: Option[] = [
  {
    text: 'TEMELIRSALIYE',
    value: 'TEMELIRSALIYE'
  },
  {
    text: 'HKSIRSALIYE',
    value: 'HKSIRSALIYE'
  }
];

export const DespatchTypeOptions: Option[] = [
  {
    text: 'SEVK',
    value: 'SEVK'
  },
  {
    text: 'MATBUDAN',
    value: 'MATBUDAN'
  }
];

export const SendTypeOptions: Option[] = [
  {
    text: 'ELEKTRONIK',
    value: 'ELEKTRONIK'
  },
  {
    text: 'KAGIT',
    value: 'KAGIT'
  }
];
export const PartyIdentificationSchemeId: Option[] = [
  { text: 'Seçiniz..', value: '0' },
  { text: 'HIZMETNO', value: 'HIZMETNO' },
  { text: 'MUSTERINO', value: 'MUSTERINO' },
  { text: 'TESISATNO', value: 'TESISATNO' },
  { text: 'TELEFONNO', value: 'TELEFONNO' },
  { text: 'DISTRIBUTORNO', value: 'DISTRIBUTORNO' },
  { text: 'TICARETSICILNO', value: 'TICARETSICILNO' },
  { text: 'TAPDKNO', value: 'TAPDKNO' },
  { text: 'BAYINO', value: 'BAYINO' },
  { text: 'ABONENO', value: 'ABONENO' },
  { text: 'SAYACNO', value: 'SAYACNO' },
  { text: 'EPDKNO', value: 'EPDKNO' },
  { text: 'SUBENO', value: 'SUBENO' },
  { text: 'PASAPORTNO', value: 'PASAPORTNO' },
  { text: 'ARACIKURUMETIKET', value: 'ARACIKURUMETIKET' },
  { text: 'ARACIKURUMVKN', value: 'ARACIKURUMVKN' },
  { text: 'CIFTCINO', value: 'CIFTCINO' },
  { text: 'IMALATCINO', value: 'IMALATCINO' },
  { text: 'DOSYANO', value: 'DOSYANO' },
  { text: 'HASTANO', value: 'HASTANO' },
  { text: 'MERSISNO', value: 'MERSISNO' }
];
export const PaymentMeansCode: Option[] = [
  { text: 'Seçiniz...', value: null as any },
  { text: ' 1 - Ödeme Tipi Muhtelif', value: '1' },
  { text: '10 - Nakit', value: '10' },
  { text: '20 - Çek', value: '20' },
  { text: '23 - Banka Çeki', value: '23' },
  { text: '42 - Havale/Eft', value: '42' },
  { text: '48 - Kredi Kartı/Banka Kartı', value: '48' }
];

export const PaymentMeansChannelCode: Option[] = [
  { text: 'Seçiniz...', value: undefined as any },
  { text: '1 - Posta', value: '1' },
  { text: '2 - Hava Yolu ile Posta', value: '2' },
  { text: '3 - Telgraf', value: '3' },
  { text: '4 - Teleks', value: '4' },
  { text: '5 - SWIFT', value: '5' },
  { text: '6 - Diğer İletişim Ağları', value: '6' },
  { text: '7 - Tanımlı Olmayan Ağlar', value: '7' },
  { text: '8 - Fedwire', value: '8' },
  { text: '9 - Bankada Elle', value: '9' },
  { text: '10 - Taahhütlü Hava Yolu ile Posta', value: '10' },
  { text: '11 - Taahhütlü Posta', value: '11' },
  { text: '12 - Kurye', value: '12' },
  { text: '13 - Özel Kurye', value: '13' },
  { text: '14 - Uluslararası Para Transferi', value: '14' },
  { text: '15 - Ulusal Para Transferi', value: '15' },
  { text: 'ZZZ - Karşılıklı Olarak Belirlenen Yol', value: 'ZZZ' }
];
export const OKCDocumentDescription: Option[] = [
  { text: 'Seçiniz...', value: null as any },
  { text: 'Avans', value: 'AVANS' },
  { text: 'Yemek Fişi Tahsilatı', value: 'YEMEK_FIS' },
  { text: 'E-fatura', value: 'E-FATURA' },
  { text: 'İrsaliye Yerine Geçen E-fatura', value: 'E-FATURA_IRSALIYE' },
  { text: 'E-Arşiv Fatura', value: 'E-ARSIV' },
  { text: 'İrsaliye Yerine Geçen E-Arşiv Fatura', value: 'E-ARSIV_IRSALIYE' },
  { text: 'Fatura', value: 'FATURA' },
  { text: 'Otopark', value: 'OTOPARK' },
  { text: 'Fatura Tahsilatı İşlemleri', value: 'FATURA_TAHSILAT' },
  { text: 'Komisyonlu Fatura Tahsilatı İşlemleri', value: 'FATURA_TAHSILAT_KOMISYONLU' }
];
export const ProductTypeOptions: Option[] = [
  {
    text: 'E-Fatura',
    value: 'EInvoice'
  },
  {
    text: 'E-İrsaliye',
    value: 'EDespatch'
  },
  {
    text: 'E-Arşiv',
    value: 'EArchive'
  },
  {
    text: 'E-Serbest Meslek Makbuzu',
    value: 'ESmm'
  },
  {
    text: 'E-Müstahsil Makbuzu',
    value: 'EMm'
  },
  {
    text: 'E-Defter',
    value: 'EBook'
  },
  {
    text: 'E-Bilet',
    value: 'ETicket'
  }
];

export const DocumentTypeOptions: Option[] = [
  {
    text: 'Gelen e-Fatura',
    value: 'IncomingInvoice'
  },
  {
    text: 'Giden e-Fatura',
    value: 'OutgoingInvoice'
  },
  {
    text: 'Gelen e-İrsaliye',
    value: 'IncomingDespatch'
  },
  {
    text: 'Giden e-İrsaliye',
    value: 'OutgoingDespatch'
  },
  {
    text: 'E-Arşiv Fatura',
    value: 'EArchiveInvoice'
  },
  {
    text: 'E-Serbest Meslek Makbuzu',
    value: 'ESmm'
  },
  {
    text: 'E-Müstahsil Makbuzu',
    value: 'EMm'
  }
];

export const PaymentMethod: Option[] = [
  { text: 'Seçiniz...', value: null as any },
  {
    text: 'KREDIKARTI/BANKAKARTI',
    value: 'KREDIKARTI/BANKAKARTI'
  },
  {
    text: 'EFT/HAVALE',
    value: 'EFT/HAVALE'
  },
  {
    text: 'KAPIDAODEME',
    value: 'KAPIDAODEME'
  },
  {
    text: 'ODEMEARACISI',
    value: 'ODEMEARACISI'
  },
  {
    text: 'DIGER',
    value: 'DIGER'
  }
];

export const SgkAccountingCost: Option[] = [
  { text: 'Seçiniz...', value: null as any },
  {
    text: 'Eczane',
    value: 'SAGLIK_ECZ'
  },
  {
    text: 'Hastane',
    value: 'SAGLIK_HAS'
  },
  {
    text: 'Optik',
    value: 'SAGLIK_OPT'
  },
  {
    text: 'Medikal',
    value: 'SAGLIK_MED'
  },
  {
    text: 'Abonelik',
    value: 'ABONELIK'
  },
  {
    text: 'Mal/Hizmet',
    value: 'MAL_HIZMET'
  },
  {
    text: 'Diğer',
    value: 'DIGER'
  }
];

export const WithouldingTaxReason: ReasonOption[] = [
  { text: 'Seçiniz...', reasonCode: '0', percent: 0 },
  {
    text: '601 - YAPIM İŞLERİ İLE BU İŞLERLE BİRLİKTE İFA EDİLEN MÜHENDİSLİK-MİMARLIK VE ETÜT-PROJE HİZMETLERİ [GT 117-Bölüm (3.2.1)]',
    reasonCode: '601',
    percent: 40
  },
  { text: '602 - ETÜT, PLAN-PROJE, DANIŞMANLIK, DENETİM VE BENZERİ HİZMETLER[GT 117-Bölüm (3.2.2)]', reasonCode: '602', percent: 90 },
  { text: '603 - MAKİNE, TEÇHİZAT, DEMİRBAŞ VE TAŞITLARA AİT TADİL, BAKIM VE ONARIM HİZMETLERİ [GT 117-Bölüm (3.2.3)]', reasonCode: '603', percent: 70 },
  { text: '604 - YEMEK SERVİS HİZMETİ [GT 117-Bölüm (3.2.4)]', reasonCode: '604', percent: 50 },
  { text: '605 - ORGANİZASYON HİZMETİ [GT 117-Bölüm (3.2.4)]', reasonCode: '605', percent: 50 },
  { text: '606 - İŞGÜCÜ TEMİN HİZMETLERİ [GT 117-Bölüm (3.2.5)]', reasonCode: '606', percent: 90 },
  { text: '607 - ÖZEL GÜVENLİK HİZMETİ [GT 117-Bölüm (3.2.5)]', reasonCode: '607', percent: 90 },
  { text: '608 - YAPI DENETİM HİZMETLERİ [GT 117-Bölüm (3.2.6)]', reasonCode: '608', percent: 90 },
  {
    text: '609 - FASON OLARAK YAPTIRILAN TEKSTİL VE KONFEKSİYON İŞLERİ, ÇANTA VE AYAKKABI DİKİM İŞLERİ VE BU İŞLERE ARACILIK HİZMETLERİ [GT 117-Bölüm (3.2.7)]',
    reasonCode: '609',
    percent: 70
  },
  { text: '610 - TURİSTİK MAĞAZALARA VERİLEN MÜŞTERİ BULMA / GÖTÜRME HİZMETLERİ [GT 117-Bölüm (3.2.8)]', reasonCode: '610', percent: 90 },
  { text: '611 - SPOR KULÜPLERİNİN YAYIN, REKLÂM VE İSİM HAKKI GELİRLERİNE KONU İŞLEMLERİ [GT 117-Bölüm (3.2.9)]', reasonCode: '611', percent: 90 },
  { text: '612 - TEMİZLİK HİZMETİ [GT 117-Bölüm (3.2.10)]', reasonCode: '612', percent: 90 },
  { text: '613 - ÇEVRE VE BAHÇE BAKIM HİZMETLERİ [GT 117-Bölüm (3.2.10)]', reasonCode: '613', percent: 90 },
  { text: '614 - SERVİS TAŞIMACILIĞI HİZMETİ [GT 117-Bölüm (3.2.11)]', reasonCode: '614', percent: 50 },
  { text: '615 - HER TÜRLÜ BASKI VE BASIM HİZMETLERİ [GT 117-Bölüm (3.2.12)]', reasonCode: '615', percent: 70 },
  { text: '616 - Diğer Hizmetler [KDVGUT-(I/C-2.1.3.2.13)]', reasonCode: '616', percent: 50 },
  { text: '617 - HURDA METALDEN ELDE EDİLEN KÜLÇE TESLİMLERİ [GT 117-Bölüm (3.3.1)]', reasonCode: '617', percent: 70 },
  {
    text: '618 - HURDA METALDEN ELDE EDİLENLER DIŞINDAKİ BAKIR, ÇİNKO DEMİR ; ÇELİK ALÜMİNYUM VE KURŞUN KÜLÇE TESLİMLERİ [KDVGUT-(I/C-2.1.3.3.1)]',
    reasonCode: '618',
    percent: 70
  },
  { text: '619 - BAKIR, ÇİNKO VE ALÜMİNYUM ÜRÜNLERİNİN TESLİMİ [GT 117-Bölüm (3.3.2)]', reasonCode: '619', percent: 70 },
  { text: '620 - İSTİSNADAN VAZGEÇENLERİN HURDA VE ATIK TESLİMİ [GT 117-Bölüm (3.3.3)]', reasonCode: '620', percent: 70 },
  {
    text: '621 - METAL, PLASTİK, LASTİK, KAUÇUK, KÂĞIT VE CAM HURDA VE ATIKLARDAN ELDE EDİLEN HAMMADDE TESLİMİ [GT 117-Bölüm (3.3.4)]',
    reasonCode: '621',
    percent: 90
  },
  { text: '622 - PAMUK, TİFTİK, YÜN VE YAPAĞI İLE HAM POST VE DERİ TESLİMLERİ [GT 117-Bölüm (3.3.5)]', reasonCode: '622', percent: 90 },
  { text: '623 - AĞAÇ VE ORMAN ÜRÜNLERİ TESLİMİ [GT 117-Bölüm (3.3.6)]', reasonCode: '623', percent: 50 },
  { text: '624 - YÜK TAŞIMACILIĞI HİZMETİ [KDVGUT-(I/C-2.1.3.2.11)]', reasonCode: '624', percent: 20 },
  { text: '625 - TİCARİ REKLAM HİZMETLERİ [KDVGUT-(I/C-2.1.3.2.15)]', reasonCode: '625', percent: 30 },
  { text: '626 - DİĞER TESLİMLER [KDVGUT-(I/C-2.1.3.3.7.)]', reasonCode: '626', percent: 20 },
  { text: '627 - DEMİR-ÇELİK ÜRÜNLERİNİN TESLİMİ [KDVGUT-(I/C-2.1.3.3.8)]”', reasonCode: '627', percent: 50 },
  {
    text: '801 - Yapım İşleri ile Bu İşlerle Birlikte İfa Edilen Mühendislik-Mimarlık ve Etüt-Proje Hizmetleri[KDVGUT-(I/C-2.1.3.2.1)]',
    reasonCode: '801',
    percent: 100
  },
  { text: '802 - Etüt, Plan-Proje, Danışmanlık, Denetim ve Benzeri Hizmetler[KDVGUT-(I/C-2.1.3.2.2)]', reasonCode: '802', percent: 100 },
  { text: '803 - Makine, Teçhizat, Demirbaş ve Taşıtlara Ait Tadil, Bakım ve Onarım Hizmetleri[KDVGUT- (I/C-2.1.3.2.3)]', reasonCode: '803', percent: 100 },
  { text: '804 - Yemek Servis Hizmeti[KDVGUT-(I/C-2.1.3.2.4)]', reasonCode: '804', percent: 100 },
  { text: '805 - Organizasyon Hizmeti[KDVGUT-(I/C-2.1.3.2.4)]', reasonCode: '805', percent: 100 },
  { text: '806 - İşgücü Temin Hizmetleri[KDVGUT-(I/C-2.1.3.2.5)]', reasonCode: '806', percent: 100 },
  { text: '807 - Özel Güvenlik Hizmeti[KDVGUT-(I/C-2.1.3.2.5)]', reasonCode: '807', percent: 100 },
  { text: '808 - Yapı Denetim Hizmetleri[KDVGUT-(I/C-2.1.3.2.6)]', reasonCode: '808', percent: 100 },
  {
    text:
      '809 - Fason Olarak Yaptırılan Tekstil ve Konfeksiyon İşleri, Çanta ve Ayakkabı Dikim İşleri ve Bu İşlere Aracılık Hizmetleri[KDVGUT-(I/C-2.1.3.2.7)]',
    reasonCode: '809',
    percent: 100
  },
  { text: '810 - Turistik Mağazalara Verilen Müşteri Bulma/ Götürme Hizmetleri[KDVGUT-(I/C-2.1.3.2.8)]', reasonCode: '810', percent: 100 },
  { text: '811 - Spor Kulüplerinin Yayın, Reklâm ve İsim Hakkı Gelirlerine Konu İşlemleri[KDVGUT-(I/C-2.1.3.2.9)]', reasonCode: '811', percent: 100 },
  { text: '812 - Temizlik Hizmeti[KDVGUT-(I/C-2.1.3.2.10)]', reasonCode: '812', percent: 100 },
  { text: '813 - Çevre ve Bahçe Bakım Hizmetleri[KDVGUT-(I/C-2.1.3.2.10)]', reasonCode: '813', percent: 100 },
  { text: '814 - Servis Taşımacılığı Hizmeti[KDVGUT-(I/C-2.1.3.2.11)]', reasonCode: '814', percent: 100 },
  { text: '815 - Her Türlü Baskı ve Basım Hizmetleri[KDVGUT-(I/C-2.1.3.2.12)]', reasonCode: '815', percent: 100 },
  { text: '816 - Hurda Metalden Elde Edilen Külçe Teslimleri[KDVGUT-(I/C-2.1.3.3.1)]', reasonCode: '816', percent: 100 },
  {
    text: '817 - Hurda Metalden Elde Edilenler Dışındaki Bakır, Çinko, Demir Çelik, Alüminyum ve Kurşun Külçe Teslimi [KDVGUT-(I/C-2.1.3.3.1)]',
    reasonCode: '817',
    percent: 100
  },
  { text: '818 - Bakır, Çinko, Alüminyum ve Kurşun Ürünlerinin Teslimi[KDVGUT-(I/C-2.1.3.3.2)]', reasonCode: '818', percent: 100 },
  { text: '819 - İstisnadan Vazgeçenlerin Hurda ve Atık Teslimi[KDVGUT-(I/C-2.1.3.3.3)]', reasonCode: '819', percent: 100 },
  {
    text: '820 - Metal, Plastik, Lastik, Kauçuk, Kâğıt ve Cam Hurda ve Atıklardan Elde Edilen Hammadde Teslimi[KDVGUT-(I/C-2.1.3.3.4)]',
    reasonCode: '820',
    percent: 100
  },
  { text: '821 - Pamuk, Tiftik, Yün ve Yapağı İle Ham Post ve Deri Teslimleri[KDVGUT-(I/C-2.1.3.3.5)]', reasonCode: '821', percent: 100 },
  { text: '822 - Ağaç ve Orman Ürünleri Teslimi[KDVGUT-(I/C-2.1.3.3.6)]', reasonCode: '822', percent: 100 },
  { text: '823 - Yük Taşımacılığı Hizmeti [KDVGUT-(I/C-2.1.3.2.11)]', reasonCode: '823', percent: 100 },
  { text: '824 - Ticari Reklam Hizmetleri [KDVGUT-(I/C-2.1.3.2.15)]', reasonCode: '824', percent: 100 },
  { text: '825 - Demir-Çelik Ürünlerinin Teslimi [KDVGUT-(I/C-2.1.3.3.8)]', reasonCode: '825', percent: 100 },
  { text: '650 - Diğer', reasonCode: '650', percent: 0 }
];

export const IhracKayitliTaxReason: Option[] = [
  { text: 'Seçiniz...', value: '0' },
  { text: '701 - 3065 s. KDV Kanununun 11/1-c md. Kapsamındaki İhraç Kayıtlı Satış', value: '701' },
  { text: '702 - DİİB ve Geçici Kabul Rejimi Kapsamındaki Satışlar', value: '702' },
  { text: '703 - 4760 s. ÖTV Kanununun 8/2 Md. Kapsamındaki İhraç Kayıtlı Satış', value: '703' },
  { text: '704 - 3065 sayılı KDV Kanununun (11/1-c) maddesi ve 4760 s. Ötv Kanununun 8/2. Md. Kapsamındaki İhraç Kayıtlı Satış', value: '704' }
];
